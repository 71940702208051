const springServeVast =
  "https://vid.springserve.com/vast/493180?w=1920&h=1080&cb={{CACHEBUSTER}}&ip={{IP}}&ua={{USER_AGENT}}&pod_max_dur=15&pod_ad_slots=1"
// const liveStreamSrc =
//   "https://sportsnet-midas.fubo.tv/v1/master/6c9193257c0eed8ca3f1eabccc4444f9477500f1/SportsNet/fsn/master.m3u8?ads.partner=fsn"
const liveStreamSrc =
  "https://offplatform-midas.fubo.tv/v1/master/cf8aed8001539cb4cab4963801d5f2cda8d77a1e/production-sportsnet/fsn/master.m3u8?ads.partner=fsn&ads.station_id=120123"
const liveStreamType = "application/x-mpegURL"

const imgxBaseUrl = "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/"

export { springServeVast, liveStreamSrc, liveStreamType }
